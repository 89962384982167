var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-wrapper" }, [
    _c("iframe", {
      ref: "iframeDom",
      staticClass: "iframe",
      attrs: { src: _vm.sharePageURL },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }