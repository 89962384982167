<template>
    <div class="iframe-wrapper ">
        <iframe ref="iframeDom" class="iframe" :src="sharePageURL"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            extraParams: ''
        }
    },
    computed: {
        pageItem() {
            return this.$store.getters['menuStore/getPageItem']
        },
        sharePageURL() {
            // return 'http://172.16.11.209:9001/shareDashboard/fd035ca28e5d48e698ec04d136167cac?type=NONE&shareId=ae7dcc2a78de4381af3d1a5553c86ca4&userId=0735eecc-a4af-44d0-8385-5fd66c9e9e15&wishareToken=5d93671d-714b-4aab-a729-a844ac3060ed'
            // return `${this.pageItem.helpUrl}&shareId=ae7dcc2a78de4381af3d1a5553c86ca4&extraParams=${this.extraParams}`
            // return "https://saasdev.wisharetec.com/charts/login"
            return `${this.pageItem.reportUrl}`

        }
    },
    methods: {
        getAesString(content, key, iv) {//加密
            const CryptoJS = require('crypto-js');  //引用AES源码js
            let data = content;

            var key = CryptoJS.enc.Utf8.parse(key);
            var iv = CryptoJS.enc.Utf8.parse(iv);
            var encrypted = CryptoJS.AES.encrypt(data, key,
                {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });
            return encrypted.toString();    //返回的是base64格式的密文
        },
        onMessage(e) {
            this.$refs.iframeDom.contentWindow.postMessage({ shareId: new URLSearchParams(this.pageItem.reportUrl).get('shareId'), status: "trans", userId: this.$store.state.userInfo.id, wishareToken: this.$store.state.token }, '*')
        }
    },

    mounted() {

        window.addEventListener('message', this.onMessage)
        // this.extraParams = this.getAesString(JSON.stringify({ userId: this.$store.state.userInfo.id, wishareToken: this.$store.state.token }), 'key', 'iv')

    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessage)
    },
}
</script>

<style lang="scss" scoped>
.iframe-wrapper {

    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    background: #fff;
    text-align: left;
    padding: 0 20px;

    .iframe {
        width: 100%;
        height: 100%;
        flex: 1;
        border: none
    }
}
</style>